/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-1742382522323-2",
  en: {
    home: "Home",
    edu: "Education and Certifications",
    experience: "Experience",
    contact: "Contact",
  },
  es: {
    home: "Inicio",
    edu: "Formacion",
    experience: "Experiencia",
    contact: "Contactar",
  },
  de: {
    home: "Start",
    edu: "Ausbildung",
    experience: "Erfahrung",
    contact: "Kontaktieren",
  },
};

//Home Page
const greeting = {

  logo_name: "solve-IT",
  nickname: "Webdev Dan",
  full_name: "Daniel",
  resumeLink:
    "https://",
  mail: "mailto:info@solve-it.net",
  en: {
    i_am: "My name is",
    title: "Welcome",
    title2: "Solve-IT: Web Development & Online Marketing",
    subTitle: "Full Stack Developer, and Online Marketing Specialist.",
    contact_me: "Contact me",
    what_i_do: "Here is what I do",
  },
  es: {
    i_am: "Mi nombre es",
    title: "Hola",
    title2: "Solve-IT: Desarrollo Web y Marketing Online",
    subTitle: "Desarrollador Full Stack y Especialista en Marketing Online.",
    contact_me: "Contáctame",
    what_i_do: "Eso es lo que hago",
  },
  de: {
    i_am: "Mein Name ist",
    title: "Hallo",
    title2: "Solve-IT: Web-Entwicklung & Online Marketing",
    subTitle: "Full Stack Entwickler und Online Marketing Spezialist.",
    contact_me: "Kontaktiere mich",
    what_i_do: "Was ich mache",
  },
  

};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/webdevdan",
  linkedin: "https://",
  gmail: "dnl.sprung@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
};

const skills = {

  data: [
    {
      fileName: "FullStackImg",
      en: {
        title: "Full Stack Web Development",
        skills: [
          "⚡ Building responsive websites & Online Applications using ReactJS, Vue.js or Vanilla Javascript for the frontend",
          "⚡ Backend Development with PHP (Laravel Framework) or NodeJS (Express.js)",
          "⚡ Experience with CSS, SASS, JQuery, SQL, MongoDB, Docker, Git and much more",
        ],

      },
      es: {
        title: "Desarrollo Web (Full Stack)",
        skills: [
          "⚡ Creación de sitios web responsivos y aplicaciones en línea utilizando ReactJS, Vue.js o Vanilla Javascript para la interfaz",
          "⚡ Desarrollo backend con PHP (Laravel Framework) o NodeJS (Express.js)",
          "⚡ Experiencia con CSS, SASS, JQuery, SQL, MongoDB, Docker, Git y mucho más",
        ],

      },
      de: {
        title: "Full Stack Web-Entwicklung",
        skills: [
          "⚡ Erstellen von responsiven Websites & Online Anwendungen mit Hilfe von ReactJS, Vue.js or Vanilla Javascript für das Frontend",
          "⚡ Backend Entwicklung mit PHP (Laravel Framework) oder NodeJS (Express.js)",
          "⚡ Erfahrung mit CSS, SASS, JQuery, SQL, MongoDB, Docker, Git und vieles mehr",
        ],

      },
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
        {
          skillName: "NodeJS (express)",
          fontAwesomeClassname: "simple-icons:express",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },

    {
      fileName: "CloudInfraImg",
      en: {
        title: "Online Marketing",
        skills: [
          "⚡ Finding the best online strategy for your business",
          "⚡ Search Engine Optimization (SEO)",  
          "⚡ Search Engine Advertising (SEA) on Google or Bing",  
          "⚡ Display (Image) Ads",
          "⚡ Social Media Ads on Facebook or Instagram",
          "⚡ Video ads on Youtube",
        ],
      },
      es: {
        title: "Mercadeo en línea",
        skills: [
          "⚡ Encontrar la mejor estrategia online para tu negocio",
          "⚡ Posicionamiento en buscadores (SEO)",  
          "⚡ Publicidad en buscadores (SEA) en Google o Bing",  
          "⚡ Anuncios de display (imagen)",
          "⚡ Anuncios en redes sociales como Facebook o Instagram",
          "⚡ Anuncios de vídeo en Youtube",
        ],
      },
      de: {
        title: "Online Marketing",
        skills: [
          "⚡ Die beste Online-Strategie für Ihr Unternehmen finden",
          "⚡ Suchmaschinen-Optimierung (SEO)",  
          "⚡ Suchmaschinenwerbung (SEA) bei Google oder Bing",  
          "⚡ Display (Bild)-Anzeigen",
          "⚡ Social Media-Anzeigen auf Facebook oder Instagram",
          "⚡ Videoanzeigen auf Youtube",
        ],
      },
      softwareSkills: [
        {
          skillName: "Google Ads",
          fontAwesomeClassname: "simple-icons:google",
          style: {
            color: "blue",
          },
        },
        {
          skillName: "Facebook",
          fontAwesomeClassname: "simple-icons:facebook",
          style: {
            color: "#4c8ced",
          },
        },
        {
          skillName: "Instagram",
          fontAwesomeClassname: "simple-icons:instagram",
          style: {
            color: "#e046e3",
          },
        },
        {
          skillName: "Youtube",
          fontAwesomeClassname: "simple-icons:youtube",
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      fileName: "DataScienceImg",
      en: {
        title: "Data & Web Analytics",
        skills: [
          "⚡ Measurement of online marketing performance (Google Analytics)",
          "⚡ Data analysis and reports from databases",
        ],
      },
      es: {
        title: "Datos y análisis web",
        skills: [
          "⚡ Medición del rendimiento del marketing online (Google Analytics)",
          "⚡ Análisis de datos e informes de bases de datos.",
        ],
      },
      de: {
        title: "Daten- und Webanalyse",
        skills: [
          "⚡ Messung der Online-Marketing-Leistung (Google Analytics)",
          "⚡ Datenanalyse und Berichte aus Datenbanken",
        ],
      },
      softwareSkills: [
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            backgroundColor: "transparent",
            color: "green",
          },
        },
        {
          skillName: "Google Analytics",
          fontAwesomeClassname: "simple-icons:googleanalytics",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
  ],
};

const degrees = {
  en: {
    education: "Education",
    degrees_received: "Degrees received",
    basic_qual: "Qualification and Certifications",
    basic_qual_sub: "",

  },
  es: {
    education: "Educación",
    degrees_received: "Grados recibidos",
    basic_qual: "Cualificación y certificaciones",
    basic_qual_sub: "",
  },
  de: {
    education: "Ausbildung / Studium",
    degrees_received: "Ausbildung",
    basic_qual: "Qualifizierung und Zertifizierungen",
    basic_qual_sub: "",
  },
  degrees: [
    {
      en: {
        title: "Stenden University",
        subtitle: "Bachelor of Business Administration (BBA)",
        duration: "",
        descriptions: [
          "⚡ Business Administration",
          "⚡ Tourism Management",
          "⚡ Real Estate",
          "⚡ Internship in Online Marketing & Web Development",
        ],
      },
      es: {
        title: "Stenden University",
        subtitle: "Licenciado en Administración de Empresas (BBA)",
        duration: "",
        descriptions: [
          "⚡ Administración de empresas",
          "⚡ Gestión turística",
          "⚡ Inmobiliario",
          "⚡ Prácticas en marketing en línea y desarrollo web",
        ],
      },
      de: {
        title: "Stenden University",
        subtitle: "Bachelor of Business Administration (BBA)",
        duration: "",
        descriptions: [
          "⚡ Business Administration",
          "⚡ Tourismus Management",
          "⚡ Immobilien",
          "⚡ Praktikum in Online Marketing & Webentwicklung",
        ],
      },
      logo_path: "stenden.jpg",
      alt_name: "DAL",
      website_link: "https://www.nhlstenden.com/",
    },
  ],
};

const certifications = {
  en: {
    title: "Certifications",
  },
  es: {
    title: "Certificaciones",
  },
  de: {
    title: "Zertifikate",
  },
  certifications: [
    {
      title: "Google Adwords Certificate",
      subtitle: "",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#1e70c1",
    }
  ],
};

// Experience Page
const experience = {
  en: {
    title: "Experience",
    subtitle: "Work, Internship and Volunteership",
    description: "I've worked for several companies and as freelancer in online marketing & Web Development.",
  },
  es: {
    title: "Experiencia",
    subtitle: "Trabajo, Prácticas y Voluntariado",
    description: "He trabajado para varias empresas y como autónomo en marketing online y desarrollo web.",
  },
  de: {
    title: "Erfahrung",
    subtitle: "Arbeit & Praktika",
    description: "Ich habe für mehrere Unternehmen und als Freiberufler im Bereich Online-Marketing und Webentwicklung gearbeitet.",
  },
  header_image_path: "experience.svg",
  sections: [
    {
      en: {
        title: "Work",
      },
      es: {
        title: "Trabajo",
      },
      de: {
        title: "Arbeit",
      },
      experiences: [
        {
          title: "Work",
          company_url: "https://www.solve-it.net",
          logo_path: "myworktime.jpg",
          color: "#f10000",
          en: {
            duration: "",
            location: "International",
            description: "I've helped companies to develop online applications based on open source technologies. From designing appealing pages to programming backends and APIs. Besides Web Development I also offer an online marketing service. I help you find the right marketing strategy and make your company visible in search engines, on Facebook, Instagram, Youtube or linkedIn",
            title: "Online Marketing & Web Development",
            company: "Self-employed",
          },
          es: {
            duration: "",
            location: "Internacional",
            description: "He ayudado a empresas a desarrollar aplicaciones online basadas en tecnologías de código abierto. Desde diseñar páginas atractivas hasta programar backends y API. Además del Desarrollo Web también ofrezco un servicio de marketing online. Te ayudo a encontrar la estrategia de marketing adecuada y a hacer visible tu empresa en los buscadores, en Facebook, Instagram, Youtube o linkedIn.",
            title: "Marketing en línea y desarrollo web",
            company: "Trabajadores por cuenta propia",
          },
          de: {
            duration: "",
            location: "International",
            description: "Ich habe Unternehmen dabei geholfen, Online-Anwendungen auf Basis von Open-Source-Technologien zu entwickeln. Von der Gestaltung ansprechender Seiten bis hin zur Programmierung von Backends und APIs. Neben der Webentwicklung biete ich auch einen Online-Marketing-Service an. Ich helfe Ihnen, die richtige Marketingstrategie zu finden und Ihr Unternehmen in Suchmaschinen, auf Facebook, Instagram, Youtube oder LinkedIn sichtbar zu machen.",
            title: "Online Marketing & Web Development",
            company: "Selbstständig",
          },
        },
        {
          company_url: "https://www.conrad.ch",
          logo_path: "conrad.jpg",
          color: "#2962FF",
          en: {
            duration: "",
            location: "Zurich, Switzerland",
            description: "Create and manage Google online campaigns for more than 600,000 products, plan and coordinate online campaigns with affiliate partners, etc.",
            title: "SEA & Affiliate Marketing Manager",
            company: "Conrad Electronics",
          },
          es: {
            duration: "",
            location: "Zurich, Suiza",
            description: "Crear y administrar campañas en línea de Google para más de 600.000 productos, planificar y coordinar campañas en línea con socios afiliados, etc.",
            title: "Gerente de SEA y marketing de afiliados",
            company: "Conrad Electronics",
          },
          de: {
            duration: "",
            location: "Zürich, Schweiz",
            description: "Erstellen und Verwalten von Google-Online-Kampagnen für mehr als 600.000 Produkte, planen und koordinieren von Online-Kampagnen mit Affiliate-Partnern, etc.",
            title: "SEA & Affiliate Marketing Manager",
            company: "Conrad Electronics",
          },
        },
        {
          company_url: "https://www.groupm.com",
          logo_path: "groupm.png",
          color: "#2962FF",
          en: {
            duration: "",
            location: "Zurich, Switzerland",
            description: "Coordinating and setting up online campaigns for international organizations and much more.",
            title: "Online Marketing Manager",
            company: "GroupM Switzerland",     
          },
          es: {
            duration: "",
            location: "Zurich, Suiza",
            description: "Coordinación y puesta en marcha de campañas online para organizaciones internacionales y mucho más.",
            title: "Gerente de marketing en línea",
            company: "GroupM Suiza",     
          },
          de: {
            duration: "",
            location: "Zürich, Schweiz",
            description: "Koordinierung und Einrichtung von Online-Kampagnen für internationale Organisationen und vieles mehr.",
            title: "Online Marketing Manager",
            company: "GroupM Schweiz",     
          },
        },
      ],
    },
    {
      en: {
        title: "Internship",
      },
      es: {
        title: "Práctica",
      },
      de: {
        title: "Praktikum",
      },
      experiences: [
        {
          company_url: "https://www.trossachs.co.uk",
          logo_path: "trossachs.png",
          color: "#56A4D3",
          en: {
            duration: "",
            location: "Stirling, Scotland",
            description: "Content creation & Web design for Great Scot's tourism websites such as trossachs.co.uk",
            title: "Internship: Content creation & Web Design",
            company: "Great Scot",
          },
          es: {
            duration: "",
            location: "Stirling, Reino Unido",
            description: "Creación de contenido y diseño web para sitios web de turismo de Gran Escocia, como trossachs.co.uk",
            title: "Prácticas: Creación de contenidos y diseño web",
            company: "Great Scot",
          },
          de: {
            duration: "",
            location: "Stirling, Schottland",
            description: "Content-Erstellung und Webdesign für Tourismus-Websites von Great Scot wie trossachs.co.uk",
            title: "Praktikum: Content-Erstellung und Webdesign",
            company: "Great Scot",
          },
        },
        {
          company_url: "https://",
          logo_path: "electronic.png",
          color: "#4285F4",
          en: {
            duration: "",
            location: "Germany",
            description:"During my time at school I already learnt a lot about programming and computers. I made custom PCs for the clients during my internships and also got my first experience in web development.",
            title: "Several Internships",
            company: "various companies",
          },
          es: {
            duration: "",
            location: "Alemania",
            description:"Durante mi etapa en el colegio ya aprendí mucho sobre programación y computación. Durante mis prácticas hice PC personalizadas para los clientes y también obtuve mi primera experiencia en desarrollo web.",
            title: "Varias Practicas",
            company: "varias empresas",
          },
          de: {
            duration: "",
            location: "Deutschland",
            description:"Während meiner Schulzeit habe ich bereits viel über Programmierung und Computer gelernt. Während meiner Praktika habe ich individuelle PCs für die Kunden angefertigt und auch erste Erfahrungen in der Webentwicklung gesammelt.",
            title: "Mehrere Praktika",
            company: "verschiedene Firmen",
          },      
        },
      ],
    },
    {
      en: {
        title: "Other work experience",
      },
      es: {
        title: "Más experiencia laboral",
      },
      de: {
        title: "Weitere Arbeitserfahrung",
      },
      experiences: [
        {
          company_url: "https://",
          logo_path: "bochum-uni.png",
          color: "#56A4D3",
          en: {
            duration: "",
            description:"Training as an industrial mechanic with successfully completed certification in Bochum, Germany",
            title: "Industrial mechanic - precision engineering",
            company: "University of Applied Sciences",
          },
          es: {
            duration: "",
            description:"Formación como mecánico industrial con certificación superada con éxito en Bochum, Alemania.",
            title: "Ingeniería de precisión mecánica industrial",
            company: "Universidad de Ciencias Aplicadas",
          },
          de: {
            duration: "",
            description:"Ausbildung zum Industriemechaniker mit erfolgreich abgeschlossener Prüfung in Bochum, Deutschland",
            title: "Industrie-Mechaniker Feintechnik",
            company: "Fachhochschule Bochum",
          },
        }

      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to develop full stack applications with PHP & Javascript. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    en: {
      title: "Contact Me",
      description: "You can contact me by sending the contact form below.",
      your_name: "Your Name",
      your_email: "Your Email",
      your_message: "Your Message",
      send_message: "Send Message",
      in_touch: "We'll be in touch soon.",
      thank_you: "Thank you!",
    },
    es: {
      title: "Enviar mensaje",
      description: "Puede ponerse en contacto conmigo directamente a través del formulario..",
      your_name: "Su Nombre",
      your_email: "Su Correo Electronico",
      your_message: "Mensaje",
      send_message: "Enviar Mensaje",
      in_touch: "Pronto estaremos en contacto.",
      thank_you: "Gracias",
    },
    de: {
      title: "Nachricht senden",
      description: "Du kannst mich direkt über das Formular kontaktieren.",
      your_name: "Ihr Name",
      your_email: "Ihre Email",
      your_message: "Nachricht",
      send_message: "Nachricht senden",
      in_touch: "Wir melden uns bald bei Ihnen.",
      thank_you: "Danke!",
    },
    
    profile_image_path: "hrishi2.png",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try my best to write a blog about it.",
    link: "https://medium.com/",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "My Work Time",
      url: "https://www.myworktime.net",
      description: "Time Tracking and project management app",
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Laravel",
          iconifyClass: "devicon-laravel",
        },
        {
          name: "Javascript",
          iconifyClass: "devicon-javascript",
        },
        {
          name: "CSS",
          iconifyClass: "devicon-css",
        },
        {
          name: "SQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
