import React from "react";
import "./ExperienceCard.css";

function ExperienceCard(props) {
  const experience = props.experience;
  const theme = props.theme;

  let language = navigator.language;
  let dashIndex = language.indexOf('-');
  let lang = dashIndex > -1? language.substring(0, dashIndex) : language;

  return (
    <div
      className="experience-card"
      style={{
        border: `1px solid ${experience["color"]}`,
        backgroundColor: theme.imageDark,
      }}
    >
      <div className="experience-card-logo-div">
        <img
          className="experience-card-logo"
          src={require(`../../assests/images/${experience.logo_path}`)}
          alt=""
        />
      </div>
      <div className="experience-card-body-div">
        <div className="experience-card-header-div">
          <div className="experience-card-heading-left">
            <h3 className="experience-card-title" style={{ color: theme.text }}>
              {lang == "de" ? experience.de.title : lang == "es" ? experience.es.title  : experience.en.title}
            </h3>
            <p
              className="experience-card-company"
              style={{ color: theme.secondaryText }}
            >
              <a
                href={experience.company_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lang == "de" ? experience.de.company : lang == "es" ? experience.es.company  : experience.en.company}
              </a>
            </p>
          </div>
          <div className="experience-card-heading-right">
            <p
              className="experience-card-duration"
              style={{ color: theme.secondaryText }}
            >
              {lang == "de" ? experience.de.duration : lang == "es" ? experience.es.duration  : experience.en.duration}
            </p>
            <p
              className="experience-card-location"
              style={{ color: theme.secondaryText }}
            >
              {lang == "de" ? experience.de.location : lang == "es" ? experience.es.location  : experience.en.location}
            </p>
          </div>
        </div>
        <p
          className="experience-card-description"
          style={{ color: theme.text }}
        >
          {lang == "de" ? experience.de.description : lang == "es" ? experience.es.description  : experience.en.description}
        </p>
      </div>
    </div>
  );
}

export default ExperienceCard;
