import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Educations from "../../containers/education/Educations";
import Certifications from "../../containers/certifications/Certifications";
import EducationImg from "./EducationImg";
import "./EducationComponent.css";
import { Fade } from "react-reveal";
import { degrees } from "../../portfolio";

function Education(props) {
  const theme = props.theme;

  let language = navigator.language;
  let dashIndex = language.indexOf('-');
  let lang = dashIndex > -1? language.substring(0, dashIndex) : language;

  return (
    <div className="education-main">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <div className="basic-education">
        <Fade bottom duration={2000} distance="40px">
          <div className="heading-div">
            <div className="heading-img-div">
              <EducationImg theme={theme} />
            </div>
            <div className="heading-text-div">
              <h1 className="heading-text" style={{ color: theme.text }}>
              {lang == "de" ? degrees.de.education : lang == "es" ? degrees.es.education  : degrees.en.education}
              </h1>
              <h3 className="heading-sub-text" style={{ color: theme.text }}>
                {lang == "de" ? degrees.de.basic_qual : lang == "es" ? degrees.es.basic_qual  : degrees.en.basic_qual}
              </h3>
              <p
                className="experience-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {lang == "de" ? degrees.de.basic_qual_sub : lang == "es" ? degrees.es.basic_qual_sub  : degrees.en.basic_qual_sub}
              </p>
            </div>
          </div>
        </Fade>
        <Educations theme={props.theme} />
        <Certifications theme={props.theme} />
      </div>
      <Footer theme={props.theme} />
    </div>
  );
}

export default Education;
