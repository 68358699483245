import React from "react";

export default function CloudInfraImg(props) {
  const theme = props.theme;
  return (
    <img
    alt=""
    src={require("../../assests/images/search.png")}
  ></img>
  );
}
