import React, { useState } from "react";
import axios from 'axios';
import { contactPageData } from "../../portfolio.js";

let ContactData = contactPageData.contactSection;

let language = navigator.language;
let dashIndex = language.indexOf('-');
let lang = dashIndex > -1? language.substring(0, dashIndex) : language;


const ContactForm = () => {

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {

    e.preventDefault();


    const inputs = e.target.elements;

    const data = {};


    for (let i = 0; i < inputs.length; i++) {

      if (inputs[i].name) {

        data[inputs[i].name] = inputs[i].value;

      }

    }


    const formData = new FormData();
    formData.append("email", data['email'])
    formData.append("message", data['message'])
    formData.append("name", data['name'])

    let thisUrl = window.location.hostname;
    let url = thisUrl == 'localhost' ? 'https://localhost/portfolio/backend/index.php' : 'https://solve-it.net/info/sendform';

    axios({
      method: 'POST',
      url: url,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    })
    .then(function (response) {
      console.log(response);
      setSubmitted(true);
    })
    .catch(function (error) {
      console.log(error);
      setSubmitted(false);
    });




  }

  

return (
    <div>

      {submitted ? (
        <div>
          <div className="text-2xl">{lang == "de" ? ContactData.de.thank_you : lang == "es" ? ContactData.es.thank_you  : ContactData.en.thank_you}</div>
          <div className="text-md">{lang == "de" ? ContactData.de.in_touch : lang == "es" ? ContactData.es.in_touch  : ContactData.en.in_touch}</div>
        </div>
      ) :


      (
      <div>
      <form

        onSubmit={handleSubmit}

        method="POST"

      >

        <div className="pt-0 mb-3">

          <input

            type="text"

            placeholder={lang == "de" ? ContactData.de.your_name : lang == "es" ? ContactData.es.your_name  : ContactData.en.your_name}

            name="name"

            className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"

            required

          />

        </div>

        <div className="pt-0 mb-3">

          <input

            type="email"

            placeholder={lang == "de" ? ContactData.de.your_email : lang == "es" ? ContactData.es.your_email  : ContactData.en.your_email}

            name="email"

            className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"

            required

          />

        </div>

        <div className="pt-0 mb-3">

          <textarea

            placeholder={lang == "de" ? ContactData.de.your_message : lang == "es" ? ContactData.es.your_message  : ContactData.en.your_message}

            name="message"

            className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"

            required

          />

        </div>

        <div className="pt-0 mb-3">

          <button

            className="active:bg-blue-600 hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none"

            type="submit"

          >

            {lang == "de" ? ContactData.de.send_message : lang == "es" ? ContactData.es.send_message  : ContactData.en.send_message}

          </button>

        </div>

      </form>
      </div>
      )}


    </div>
  
  );

};


export default ContactForm;