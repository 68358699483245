import React from "react";

export default function DataScienceImg(props) {
  const theme = props.theme;
  return (
    <img
    alt=""
    src={require("../../assests/images/data.png")}
  ></img>
  );
}
