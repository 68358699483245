import React from "react";
import "./FeelingProud.css";

function FeelingProud(props) {
  const theme = props.theme;
  return ( 
  <img
    alt=""
    src={require("../../assests/images/greeting.png")}
  ></img>
    );
}

export default FeelingProud;
