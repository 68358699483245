import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio";

export default function Skills(props) {

  let language = navigator.language;
  let dashIndex = language.indexOf('-');
  let lang = dashIndex > -1? language.substring(0, dashIndex) : language;

  let what_i_do = lang == "de" ? greeting.de.what_i_do : lang == "es" ? greeting.es.what_i_do  : greeting.en.what_i_do;

  const theme = props.theme;

  return (
    <div className="main" id="skills">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            {what_i_do}
          </h1>
        </Fade>
      </div>
      <SkillSection theme={theme} />
    </div>
  );
}
